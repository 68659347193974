import Graphic from "@arcgis/core/Graphic";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import MapView from "@arcgis/core/views/MapView";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import Polygon from "@arcgis/core/geometry/Polygon";
import { planarArea as calcPlanarArea } from "@arcgis/core/geometry/geometryEngine";
import { IAttributes, IParcel } from "../contexts/BoundsUI.context";
import Extent from "@arcgis/core/geometry/Extent";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import { Parcellightboxlayer } from "../layers/Layers";

export const kansasExtent = new Extent({
  xmin: -11562053.908302132,
  ymin: 4366635.862321057,
  xmax: -10352659.401988627,
  ymax: 4984145.707110061,
  spatialReference: new SpatialReference({ wkid: 102100 }),
});

export const zoomToParcel = async (parcelAPN: string, view: __esri.MapView) => {
  const result = await queryFeatureSet({
    featureLayer: Parcellightboxlayer,
    parcelLocation: parcelAPN,
    view,
    outFields: ["*"],
  });
  if (result) {
    const { features } = result;
    const feature = features[0];
    const geometry = feature.geometry;
    view.goTo({ target: geometry, zoom: 19 });

    highlightGraphic(view, feature);
  } else {
    console.error("Parcel not found.");
  }
};

export const zoomToCounty = async (county: string, view: __esri.MapView) => {
  const result = await queryFeatureSet({
    featureLayer: Parcellightboxlayer,
    parcelLocation: county,
    view,
    outFields: ["*"],
  });
  if (result) {
    const { features } = result;
    const feature = features[0];
    const geometry = feature.geometry;
    view.goTo({ target: geometry, zoom: 10 });

    highlightGraphic(view, feature);
  } else {
    console.error("Parcel not found.");
  }
};

interface QueryFeatureSetParams {
  featureLayer: FeatureLayer;
  parcelLocation: __esri.ScreenPoint | string;
  parcelLId?: string;
  view: __esri.MapView;
  outFields: string[];
  spatialRelationship?:
    | "intersects"
    | "contains"
    | "crosses"
    | "disjoint"
    | "envelope-intersects"
    | "index-intersects"
    | "overlaps"
    | "touches"
    | "within"
    | "relation"
    | undefined;
  returnGeometry?: boolean;
}

export const createHighlightGraphic = (geometry: Polygon) => {
  return new Graphic({
    geometry: geometry,
    symbol: new SimpleFillSymbol({
      outline: {
        color: [99, 0, 235],
        width: 5,
      },
    }),
  });
};

export const queryFeatureSet = async ({
  featureLayer,
  parcelLocation,
  view,
  outFields,
  spatialRelationship = "intersects",
  returnGeometry = true,
}: QueryFeatureSetParams): Promise<__esri.FeatureSet | null> => {
  try {
    if (typeof parcelLocation === "string") {
      const featureSet = await featureLayer.queryFeatures({
        where: `Parcel_LID = '${parcelLocation}'`, // Replace 'ParcelID' with the actual field name for the parcel ID
        outFields: [
          "Acreage",
          "OBJECTID",
          "OWNER_NAME",
          "OWNER_NAME_1",
          "Mortgage",
          "PARCEL_LID",
        ],
        returnGeometry: true,
      });
      return featureSet;
    } else {
      const featureSet = await featureLayer.queryFeatures({
        geometry: view.toMap(parcelLocation),
        spatialRelationship,
        returnGeometry,
        outFields: outFields,
      });

      return featureSet;
    }
  } catch (error) {
    console.error("Error querying feature set:", error);
    return null;
  }
};

export const generateGraphicNumber = (polygon: Polygon, index: number) => {
  const centroid = polygon.centroid.clone();
  const planarArea = calcPlanarArea(polygon, "square-meters");
  if (planarArea > 50000) centroid.y += 95;
  if (planarArea < 50000 && planarArea > 25000) centroid.y += 45;
  if (planarArea < 25000 && planarArea > 5000) centroid.y += 15;
  if (planarArea < 5000) centroid.y += 5;

  return new Graphic({
    geometry: centroid,
    symbol: new TextSymbol({
      text: index.toString(),
      color: "white",
      haloColor: [99, 0, 235],
      haloSize: "1px",
      font: {
        size: 18,
        family: "sans-serif",
        weight: "bold",
      },
    }),
  });
};

export const updateLabelGraphics = (parcels: IParcel[], view: MapView) => {
  view.graphics.removeAll();
  parcels.forEach((parcel, index) => {
    view.graphics.add(parcel.graphic);
    const labelGraphic = generateGraphicNumber(
      parcel.graphic.geometry as Polygon,
      index + 1
    );
    view.graphics.add(labelGraphic);
    parcel.label.graphic = labelGraphic;
  });
};

export const highlightGraphic = async (
  view: __esri.MapView,
  feature: __esri.Graphic
) => {
  try {
    const attributes = feature.attributes;
    const geometry = feature.geometry;
    const polygon = geometry as Polygon;
    const polygonGraphic = createHighlightGraphic(polygon);
    if (attributes.OWNER_NAME && geometry && geometry.type === "polygon") {
      view.graphics.add(polygonGraphic);
    } else {
      console.error("Geometry is not a Polygon");
    }
  } catch (error) {
    console.error(error);
  }
};

export const highlightGraphicNLabel = async (
  view: __esri.MapView,
  feature: __esri.Graphic,
  count: number
) => {
  try {
    const polygon = feature.geometry as Polygon;
    if (polygon.type === "polygon") {
      const polygonGraphic = createHighlightGraphic(polygon);
      const labelGraphic = generateGraphicNumber(polygon, count);
      view.graphics.add(polygonGraphic);
      view.graphics.add(labelGraphic);
    } else {
      console.error("Geometry is not a Polygon");
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateParcelAttributes = (
  parcels: IParcel[],
  lastParcelId: number,
  values: { crossAcres: number; crossMortgage: number; acresAll: number }
): any[] => {
  return parcels.map((parcel) =>
    parcel.attributes.OBJECTID === lastParcelId
      ? {
          ...parcel,
          attributes: {
            ...parcel.attributes,
            crossAcres: values.crossAcres,
            totalMortgage: values.crossMortgage,
            totalAcres: values.acresAll,
          },
        }
      : parcel
  );
};

export const updateParcels = (
  prevParcelData: IParcel[],
  feature: any,
  view: any,
  parcel: any,
  goToFeature = false
): IParcel[] => {
  let parcelData = prevParcelData || [];
  const objectId: string = feature.getAttribute("OBJECTID");
  const polygon = feature.geometry as Polygon;

  if (
    !parcelData.some((item) => item.attributes.OBJECTID === Number(objectId))
  ) {
    highlightGraphicNLabel(view, feature, parcelData.length + 1);
    parcelData = [
      ...parcelData,
      {
        attributes: {
          OBJECTID: Number(objectId),
          PARCEL_LID: parcel?.parcel_lid,
          PARCEL_APN: parcel?.parcel_apn,
          PRIMARY_ASSESSMENT_LID: parcel?.primary_assessment_lid,
          UNIQUE_TAXAPN: parcel?.unique_taxapn,
          OWNER_NAME: parcel?.owner_name,
          OWNER_NAME_1: parcel?.owner_name_1,
          Legal_Full: parcel?.legal_full,
          COUNTY: parcel?.county,
          SECTION: parcel?.section,
          TOWNSHIP: parcel?.township,
          RANGE: parcel?.range,
          total_owned_acreage: parcel?.total_owned_acreage,
          total_mortgage_acreage: parcel?.total_mortgage_acreage,
          total_mortgage_amount: parcel?.total_mortgage_amount,
          ACREAGE: parcel?.acreage,
          Min_: parcel?.min_,
          Max_: parcel?.max_,
          loan_institution: parcel?.loan_institution,
          Mortgage: parcel?.mortgage,
          mortgage_date: parcel?.mortgage_date,
          mortgage_amount: parcel?.mortgage_amount,
          UCC: parcel?.ucc,
          date_assr_tape_cut: parcel?.date_assr_tape_cut,
        } as IAttributes,
        county: parcel?.county,
        geometry: polygon,
        graphic: createHighlightGraphic(polygon),
        label: {
          id: Number(objectId),
          graphic: generateGraphicNumber(polygon, parcelData.length + 1),
        },
      },
    ];
  }

  if (goToFeature) {
    view.goTo({ target: feature.geometry, zoom: 16 });
  }

  return parcelData;
};
