import React, { useRef, useEffect, useState, useCallback } from "react";
import Map from "@arcgis/core/Map";
import esriConfig from "@arcgis/core/config";
import MapView from "@arcgis/core/views/MapView";
import useBoundsUI from "../../../hooks/useBoundsUI";
import "./RecentMortgages.scss";
import {
  ElevationGroupLayer,
  WaterGroupLayer,
  NavigationGroupLayer,
  CDLGroupLayer,
  FinanceGroupLayer,
  Parcellightboxlayer,
  CityGroupLayer,
  WaterWellsGroupLayer,
  SoilsGroupLayer,
  AircraftGroupLayer,
} from "../../../layers/Layers";
import GroupLayer from "@arcgis/core/layers/GroupLayer.js";
import ZoomControls from "../../features/MapControllers/ZoomControls";
import CenterMapControl from "../../features/MapControllers/CenterMapControl";
import FinancialServices from "../../features/MapControllers/FinancialServices";
import {
  getYieldData,
  getCropDataByParcel,
  getPropertyTaxByAssessment,
  getElevationDataByParcelId,
} from "../../../services/insights";
import { getExtendedParcel } from "../../../services/parcel";
import { DataOption } from "../../../types/insights";
import SelectionWidget from "../../features/MapControllers/SelectionWidget";
import {
  createHighlightGraphic,
  generateGraphicNumber,
  highlightGraphicNLabel,
  kansasExtent,
  queryFeatureSet,
  updateParcels,
  zoomToParcel,
} from "../../../utils/map";
import Polygon from "@arcgis/core/geometry/Polygon";
import { IAttributes, IParcel } from "../../../contexts/BoundsUI.context";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedParcelLId } from "../../../redux/selectors/popupSelector";
import { setSelectedParcelLId } from "../../../redux/actions";
const DisplayFeatureLayer = () => {
  const dispatch = useDispatch();
  const selectedParcelLId = useSelector(selectSelectedParcelLId);
  const {
    mainMapView: { view, setView },
    featureLayer: { group },
    searchResults: { point },
    insightData: { insightType, setData, setInsightError },
    selection: { parcels, setParcels },
  } = useBoundsUI();

  const mapRef = useRef<HTMLDivElement>(null);
  const [map] = useState<Map>(new Map({ basemap: "osm/hybrid" }));
  const [clickHandler, setClickHandler] = useState<IHandle | null>(null);

  esriConfig.apiKey = process.env.REACT_APP_ARCGIS_API_KEY as string;

  const performHitTest = async (
    screenPoint: { x: number; y: number },
    view: __esri.MapView
  ): Promise<__esri.Graphic | null> => {
    const response = await view.hitTest(screenPoint);
    if (response.results.length) {
      const graphicHit = response.results.find(
        (result) => result.type === "graphic" && result.graphic?.layer
      ) as __esri.GraphicHit;
      return graphicHit?.graphic || null;
    }
    return null;
  };

  const zoomToLayer = async (graphic: __esri.Graphic, view: __esri.MapView) => {
    if (graphic.layer) {
      switch (graphic.layer.id) {
        case "PLSSFirstDivision": {
          view.goTo({ target: graphic.geometry, zoom: 15 });
          break;
        }
        case "Civil_Divisions": {
          view.goTo({ target: graphic.geometry, zoom: 13 });
          break;
        }
        case "Counties": {
          view.goTo({ target: graphic.geometry, zoom: 12 });
          break;
        }
        default:
          break;
      }
    }
  };

  const fetchInsightData = async (
    insightType: DataOption,
    signal: AbortSignal
  ) => {
    setInsightError(null);
    if (parcels.length > 0) {
      const feature = parcels[parcels.length - 1];
      switch (insightType) {
        case DataOption.CropHistory: {
          const cropData = await getCropDataByParcel(
            feature.attributes.PARCEL_LID
          );
          if (Object.keys(cropData).length === 0) {
            setInsightError("No crop history data available.");
          } else {
            setData(cropData);
          }
          break;
        }
        case DataOption.Yield: {
          const cropYieldData = await getYieldData(feature.attributes.COUNTY);
          if (Object.keys(cropYieldData).length === 0) {
            setInsightError("No crop yield data available.");
          } else {
            setData(cropYieldData);
          }
          break;
        }
        case DataOption.PropertyTax: {
          await getPropertyTaxByAssessment(
            feature.attributes.PRIMARY_ASSESSMENT_LID
          )
            .then((data) => {
              setData(data);
            })
            .catch((error) => {
              setInsightError("No tax information available.");
            });
          break;
        }
        case DataOption.Elevation: {
          /* Call elevation endpoint */
          try {
            const elevationData = await getElevationDataByParcelId(
              feature.attributes.PARCEL_LID
            );
            if (
              !elevationData ||
              !elevationData.min_elevation ||
              !elevationData.max_elevation
            ) {
              setInsightError("No elevation data available.");
              setData([]);
            } else {
              setData([
                {
                  minElevation: elevationData.min_elevation,
                  maxElevation: elevationData.max_elevation,
                  meanSlope: elevationData.mean_slope,
                  minSlope: elevationData.min_slope,
                  maxSlope: elevationData.max_slope,
                },
              ]);
            }
          } catch (error) {
            setInsightError("No elevation data available.");
            setData([]);
          }
          break;
        }
        default:
          break;
      }
    } else {
      setData([]);
    }
  };
  const handleClick = useCallback(
    async (event: { x: number; y: number }) => {
      if (view) {
        const screenPoint = { x: event.x, y: event.y };
        const graphic = await performHitTest(screenPoint, view);

        if (graphic?.layer.id === "Parcellightboxlayer") {
          const featureSet = await queryFeatureSet({
            featureLayer: Parcellightboxlayer,
            parcelLocation: screenPoint,
            view,
            outFields: ["OBJECTID", "PARCEL_LID"],
            returnGeometry: true,
          });

          const feature = featureSet?.features?.[0];
          if (feature) {
            const parcel = await getExtendedParcel(
              feature.attributes.PARCEL_LID
            );

            setParcels((prevParcelData: IParcel[]) =>
              updateParcels(prevParcelData, feature, view, parcel, false)
            );
          }
        }

        if (graphic?.layer?.parent?.get("title") === "NavigationGroupLayer") {
          zoomToLayer(graphic, view);
        }
      }
    },
    [view, group]
  );

  useEffect(() => {
    if (view && selectedParcelLId) {
      const updateSelectedParcel = async () => {
        const featureSet = await queryFeatureSet({
          featureLayer: Parcellightboxlayer,
          parcelLocation: selectedParcelLId,
          view,
          outFields: ["OBJECTID", "PARCEL_LID"],
          returnGeometry: true,
        });

        const feature = featureSet?.features?.[0];
        if (feature) {
          const parcel = await getExtendedParcel(feature.attributes.PARCEL_LID);

          setParcels((prevParcelData: IParcel[]) =>
            updateParcels(prevParcelData, feature, view, parcel)
          );

          zoomToParcel(feature.attributes.PARCEL_LID, view);
        }

        dispatch(setSelectedParcelLId(null));
      };

      updateSelectedParcel();
    }
  }, [view, selectedParcelLId]);

  const insights = useCallback(async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    if (view && insightType) {
      setData(null);
      try {
        await fetchInsightData(insightType, signal);
      } catch (error) {
        setData([]);
      }
    }
    return () => {
      abortController.abort();
    };
  }, [parcels, insightType]);

  useEffect(() => {
    if (insightType) insights();
  }, [parcels, insightType]);

  useEffect(() => {
    if (mapRef.current) {
      const mapView = new MapView({
        container: mapRef.current,
        map: map,
        extent: kansasExtent,
        constraints: { minZoom: 6 },
      });

      mapView
        .when(() => {
          mapView.ui.remove("zoom");
          mapView.goTo({ target: point, zoom: 10 });
          setView(mapView);
          mapView.popup = {
            dockEnabled: true,
            defaultPopupTemplateEnabled: true,
            dockOptions: {
              buttonEnabled: false,
              breakpoint: false,
              position: "bottom-right",
            },
          } as __esri.Popup;
        })
        .catch((error) => {
          console.error("Error during MapView initialization:", error);
        });

      map.add(CityGroupLayer, 0);
      map.add(WaterWellsGroupLayer, 0);
      map.add(AircraftGroupLayer, 0);
      map.add(NavigationGroupLayer, 0);
      map.add(FinanceGroupLayer, 0);
      map.add(SoilsGroupLayer, 0);
      map.add(WaterGroupLayer, 0);
      map.add(ElevationGroupLayer, 0);
      map.add(CDLGroupLayer, 0);
    }
  }, []);

  useEffect(() => {
    clickHandler?.remove();
    if (view) {
      setClickHandler(view.on("click", handleClick));
    }
  }, [insightType, view, group]);

  useEffect(() => {
    if (group != null) {
      let MapGroupLayer: GroupLayer;
      switch (group.groupName) {
        case "WaterLayer":
          MapGroupLayer = WaterGroupLayer;
          break;
        case "ElevationGroupLayer":
          MapGroupLayer = ElevationGroupLayer;
          break;
        case "CDLGroupLayer":
          MapGroupLayer = CDLGroupLayer;
          break;
        case "WaterWellsGroupLayer":
          MapGroupLayer = WaterWellsGroupLayer;
          break;
        case "SoilsGroupLayer":
          MapGroupLayer = SoilsGroupLayer;
          break;
        case "AircraftGroupLayer":
          MapGroupLayer = AircraftGroupLayer;
          break;
        case "FinanceLayer":
          MapGroupLayer = FinanceGroupLayer;
          break;
        default:
          MapGroupLayer = new GroupLayer();
      }
      if (group.layers == null) {
        if (MapGroupLayer.visible) MapGroupLayer.visible = false;
      } else {
        if (!MapGroupLayer.visible) MapGroupLayer.visible = true;
        MapGroupLayer.allLayers.forEach((layer) => {
          layer.visible = (group.layers as string[]).includes(layer.id);
        });
      }
    }
  }, [group]);

  return (
    <div
      className="webmap"
      ref={mapRef}
      style={{ width: "100%", height: "100vh" }}
    >
      {view && (
        <div className="fixed right-0 z-10 mr-6 mt-6">
          <div className="flex gap-6">
            <CenterMapControl view={view} />
            <ZoomControls view={view} />
            <FinancialServices view={view} />
            <SelectionWidget view={view} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplayFeatureLayer;
