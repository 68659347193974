import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Input from "../../../atoms/Input";
import Title from "../../../atoms/Title";
import { getUccFile, uccSearch } from "../../../../services/dashboard";
import {
  PAGE_SIZE,
  UCC_SEARCH_CATEGORY_OPTIONS,
} from "../../../../utils/constants";
import { SelectComponent } from "../../../atoms/Select";
import { useForm } from "react-hook-form";
import { tableSearchSchema } from "../../../../schemas/dashboard";
import { yupResolver } from "@hookform/resolvers/yup";
import useDebounce from "../../../../hooks/useDebounce";
import { IOption } from "../../../../types/search";
import {
  IUccAuthPartyData,
  IUccCollateralData,
  IUccDebtorData,
  IUccSearchParams,
  IUccSearchResponse,
  IUccSecPartyData,
} from "../../../../types/ucc";
import useBoundsUI from "../../../../hooks/useBoundsUI";
import DashboardTable from "../DashboardTable";
import { IColumn } from "../DashboardTable/DashboardTable";
import { formatDate } from "../../../../utils/date";
import ExpandableText from "../../../molecules/ExpandableText/ExpandableText";

const UccSearch = () => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const [queryParams, setQueryParams] = useSearchParams();
  const queryCategory = queryParams.get("category");
  const queryText = queryParams.get("search");
  const queryParcel_lid = queryParams.get("parcel_lid");

  const getInitialSearchCategoryOption = () => {
    return UCC_SEARCH_CATEGORY_OPTIONS.find(
      (item) => item.value === queryCategory
    );
  };
  const { control, register, watch, getValues, setValue } = useForm({
    resolver: yupResolver(tableSearchSchema),
  });
  const {
    sidebarMenu: { setOpen },
  } = useBoundsUI();

  const [data, setData] = useState<IUccSearchResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingPagination, setLoadingPagination] = useState(true);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState<{ [x: string]: string }>(
    queryCategory && queryText ? { [queryCategory]: queryText } : {}
  );
  const [parcelLid, setParcelLid] = useState<string | null>(queryParcel_lid);
  const category = watch("searchCategory") as unknown as IOption;
  const inputSearch = watch("search");
  const debouncedInputSearch = useDebounce(inputSearch, 1000);

  const clearSearch = () => {
    setValue("searchCategory", "");
    setValue("search", "");
    setSearchParams({});
    queryParams.delete("category");
    queryParams.delete("search");
    queryParams.delete("parcel_lid");
    setQueryParams(queryParams);
  };

  useEffect(() => {
    setOpen(false);
    if (queryText) {
      setValue("search", queryText);
    }
  }, []);

  const getUccData = useCallback(
    async (signal: AbortSignal) => {
      try {
        const requestParams: IUccSearchParams = {
          page: page,
          page_size: PAGE_SIZE,
          search: searchParams.searchQuery,
        };

        if (parcelLid) {
          requestParams["parcel_lid"] = parcelLid;
        }

        if (searchParams.category === "address") {
          requestParams["search_table"] = "ucc_debtors";
          requestParams["search_fields"] =
            "ub_addr1,ub_addr2,ub_addr3,ub_addr4,ub_city,ub_state,ub_country,ub_zip";
        } else if (searchParams.category) {
          requestParams["search_table"] = "ucc_" + searchParams.category;
        }
        return await uccSearch(requestParams, signal);
      } catch (error) {
        return null;
      }
    },
    [page, searchParams]
  );

  useEffect(() => {
    setLoading(true);
    getUccData(signal).then((result) => {
      if (!signal.aborted) {
        setData(result);
        setLoading(false);
        setLoadingPagination(false);
      }
    });
    return () => {
      abortController.abort();
    };
  }, [page, searchParams, getUccData]);

  useEffect(() => {
    if (category && debouncedInputSearch) {
      setLoadingPagination(true);
      setSearchParams({
        category: category.value,
        searchQuery: debouncedInputSearch,
        parcel_lid: queryParcel_lid || "",
      });
      setQueryParams({
        category: category.value,
        search: debouncedInputSearch,
      });
      setPage(1);
    }
  }, [category, debouncedInputSearch]);

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const openUccFile = (event: any, filepath: string) => {
    const button = event.target as HTMLButtonElement;
    button.disabled = true;
    getUccFile(filepath).then((response: any) => {
      if (response) {
        const blob = new Blob([response], { type: "application/pdf" });
        const blobUrl = window.URL.createObjectURL(blob);
        window.open(blobUrl);
        button.disabled = false;
      }
    });
  };

  const columns = React.useMemo<IColumn[]>(
    () => [
      {
        field: "u1_code",
        header: "UCC1 Code",
        transform: (val) => {
          return val;
        },
        minWidth: 125,
      },
      {
        field: "u3_code",
        header: "UCC3 Code",
        transform: (val) => {
          return val;
        },
        minWidth: 125,
      },
      {
        field: "entryts",
        header: "Date",
        transform: (val) => {
          return formatDate(val);
        },
        minWidth: 100,
      },
      {
        field: "filepath",
        header: "File",
        transform: (val) => {
          if (val) {
            return (
              <button
                className="bg-boundsYellow-50 text-black py-1 px-2 rounded-lg min-w-8"
                onClick={(event) => {
                  if (val) {
                    openUccFile(event, val);
                  }
                }}
              >
                View
              </button>
            );
          }
          return "N/A";
        },
        minWidth: 50,
      },
      {
        field: "us_json",
        header: "Sec Party",
        transform: (val) => {
          if (!val) return "";
          const json = JSON.parse(val);
          return (
            <div className="flex flex-col gap-2">
              {json &&
                json.map((item: IUccSecPartyData, index: number) => {
                  return (
                    <div key={"us-" + index}>
                      {[
                        item.us_prefix,
                        item.us_first_name,
                        item.us_middle_name,
                        item.us_last_name,
                      ]
                        .filter(Boolean)
                        .join(" ")}
                      {item.us_suffix && ", " + item.us_suffix}
                      <br />
                      {item.us_addr1}
                      {item.us_addr2 && (
                        <>
                          <br />
                          {item.us_addr2}
                        </>
                      )}
                      {item.us_addr3 && (
                        <>
                          <br />
                          {item.us_addr3}
                        </>
                      )}
                      {item.us_addr4 && (
                        <>
                          <br />
                          {item.us_addr4}
                        </>
                      )}
                      <br />
                      {item.us_city}, {item.us_state} {item.us_zip}
                      {item.us_country && (
                        <>
                          <br />
                          {item.us_country}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          );
        },
        minWidth: 150,
      },
      {
        field: "uc_json",
        header: "Collateral",
        transform: (val) => {
          if (!val) return "";
          const json = JSON.parse(val);

          const fullText = json
            .map((item: IUccCollateralData) => {
              return item.uc_text_notes || "";
            })
            .filter(Boolean)
            .join("\n\n");

          return <ExpandableText text={fullText} maxLength={400} />;
        },
        minWidth: 200,
        maxWidth: 800,
      },
      {
        field: "ub_json",
        header: "Debtor",
        transform: (val) => {
          if (!val) return "";
          const json = JSON.parse(val);

          const fullText = json
            .map((item: IUccDebtorData) => {
              return [
                [
                  item.ub_prefix,
                  item.ub_first_name,
                  item.ub_middle_name,
                  item.ub_last_name,
                ]
                  .filter(Boolean)
                  .join(" ") + (item.ub_suffix ? `, ${item.ub_suffix}` : ""),
                item.ub_addr1,
                item.ub_addr2,
                item.ub_addr3,
                item.ub_addr4,
                `${item.ub_city}, ${item.ub_state} ${item.ub_zip}`,
                item.ub_country,
              ]
                .filter(Boolean)
                .join("\n");
            })
            .join("\n\n");
          return <ExpandableText text={fullText} maxLength={350} />;
        },
        minWidth: 200,
      },
    ],
    []
  );

  return (
    <div className="p-8">
      <div className="border-4 border-boundsPurple-500 bg-boundsPurple-600 py-6 px-10 rounded-[30px]">
        <div className="flex justify-between">
          <Title label="UCC Search" />
          <form className="flex gap-7">
            <SelectComponent
              control={control}
              options={UCC_SEARCH_CATEGORY_OPTIONS}
              label={"Search Category"}
              variant="secondary"
              name={"searchCategory"}
              defaultValue={getInitialSearchCategoryOption()}
              closeMenuOnSelect={true}
            />
            <div className="relative">
              <Input label={"Search"} {...register("search")} />
              {getValues("search") && (
                <svg
                  className="absolute right-3 bottom-[2.5rem] h-1/4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={clearSearch}
                >
                  <path
                    fill="black"
                    d="m23.666 2.683-2.35-2.35L12 9.65 2.683.333l-2.35 2.35L9.65 12 .333 21.317l2.35 2.35L12 14.35l9.316 9.317 2.35-2.35L14.35 12l9.316-9.317Z"
                  />
                </svg>
              )}
            </div>
          </form>
        </div>
        <DashboardTable
          columns={columns}
          rows={data?.data || []}
          total_count={data?.total_count ?? 0}
          isLoading={loading}
          isLoadingPagination={loadingPagination}
          page={page}
          page_size={PAGE_SIZE}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default UccSearch;
